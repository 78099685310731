import './index.css';
import React, { useState, useEffect } from 'react';

function Home() {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch('/public/index.html')  // Cargamos el archivo HTML desde la carpeta 'public'
      .then((response) => response.text())  // Convierte la respuesta en texto
      .then((data) => setHtmlContent(data))  // Actualiza el estado con el contenido HTML
      .catch((error) => console.error('Error al cargar el HTML:', error));  // Maneja errores
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}

export default Home;
